.layout {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {

}
.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
