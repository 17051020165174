.lightbox {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:1000;
  display: flex;
  align-items:center;
  justify-content:center;
}
.lightboxBackdrop {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:black;
  opacity:0.5;
  z-index:-1;
}
.lightboxContent {
  flex-shrink:1;
}
.parchment {
  max-width: 1000px;
  margin-left: auto;
  margin-right:auto;
  container-type: inline-size;
}
