.layout {
  flex: 1 1 auto;
  display: flex;
  width:100vw;
  z-index:1;
}

.toggle {
  background-color: rgba(255,255,255,70%) !important;
  padding: 0 3px;
  position:absolute;
  top: 11px;
  left:10;
}

.toggleExpanded {
  margin-left: 300px;
}

.sidebar {
  width: 300px;
  position: fixed;
  overflow:scroll;
  padding-bottom:50px;
  bottom:0px;
  top:0px;
  background-image: linear-gradient(90deg, white 0%, white 97%, lighten(#8f5922, 50%) 97%, white 100%);
  z-index:1;
}

.bodyWithSidebar {
  margin-left:305px;
  flex: 1;
  overflow:scroll;
}

.bodyWithoutSidebar {
  margin-left:5px;
  flex: 1;
  overflow:scroll;
}
