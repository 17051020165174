.card {
  height:189px;
  width: 135px;
  text-align: center;
  .label {
    display:none;
    position:absolute;
    bottom:0;
    right:0;
    left:0;
    background-color: rgba(255,255,255, 0.55);
  }
  .name {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 18px;
    margin:0;
  }
  .controls {
    width:100%;
  }
  margin-right:2px;
  overflow:none;
  position:relative;
  img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:189px;
    width:135px;
    bottom:0px;
    object-fit:contain;
    object-position:middle;
    border-radius: 15px;
    filter: drop-shadow(2px 3px 5px rgba(0,0,0,0.3));
  }

}
.link, .link:hover {
  text-decoration: none;
  color: black;
}

.card:hover .label {
  display:block;
}
