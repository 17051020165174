.container {
  position:fixed;
  top: 47px;
  bottom:0px;
  right:0px;
  left:0px;
  text-align: center;
  background-color:rgb(100,100,100);
  .sceneImage {
    img {
      position:absolute;
      left:0;
      right:0;
      width:100%;
      height:100%;
      object-fit:cover;
      object-position:center;
      z-index:-1;
    }
  }
  .headerContainer {
    position:absolute;
    bottom:20px;
    left:0;
    right:0;
  }
  h2 {
    background-color: rgba(255,255,255, 0.55);
    padding: 5px 70px;
    display:inline;
    border-radius:3px;
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

.playerCharactersContainer {
    position:absolute;
    bottom:0px;
    left:0;
}

.referencesContainer {
    position:absolute;
    bottom:0px;
    right:0;
}

.lootBox {
  width:1060px;
  height:650px;
  background-size:1060px 650px;
  margin:auto;
  margin-top:200px;
  padding: 40px 50px;
  background-image: url('crate.png');
  .lootableItem {
    float:left;
  }
  .lootBoxContents {
    height: 567px;
    overflow:scroll;
  }
}
