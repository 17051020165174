.card {
  max-height: 300px;
  aspect-ratio: 1/1.4;
  text-align: center;
  h2 {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 18px;
  }
}
.heroImage {
  flex: 1 1 auto;
  max-height:223px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
  img {
    max-width: none;
  }
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size:12px;
  line-clamp: 3;
}
.link, .link:hover {
  flex-grow:0;
  flex-basis: 215px;
  min-width: 140px;
  text-decoration: none;
  color: black;
}
