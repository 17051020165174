.flyover {
  left:0;
  background-color:white;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 100;
  display:flex;
  flex-direction:column;
  position: absolute;
  margin-left:-9px;
  button {
    white-space: nowrap;
    text-align: left;
  }
}
