.profileImage {
  max-height:200px;
  flex-shrink:0;
  img {
    cursor:zoom-in;
    max-height:200px;
    max-width:200px;
  }
  z-index: 1;
}
.floatedProfileImage {
  float:right;
  display:none;
}
.inlineProfileImage {
  margin-left:auto;
  margin-right: auto;
  max-width:75cqw;
  max-height:600px;
  img {
    max-width:75cqw;
    max-height:600px;
  }
}

.controls {
  display:flex;
  gap: 10px;
  justify-items: flex-start;
  align-items: flex-start;
  float:right;
  padding-right: 10px;
}
.mainHeader {
  margin-left: 10px;
}
@container (min-width: 700px) {
  .floatedProfileImage {
    display:block;
  }
  .inlineProfileImage {
    display:none;
  }
}

.useInlineProfileImage {
  .floatedProfileImage {
    display:none;
  }
  .inlineProfileImage {
    display:block;
  }
}
