.container {
  position:absolute;
  right: 0;
  top: 0;
  z-index:1;
}
.logoButton {
  background-color: rgba(255,255,255,0.9);
  border:0;
  border-radius:20px;
  padding:5px;
  margin-top:5px;
  margin-right:5px;
  img {
    height:24px;
  }
}
.menu {
  position:absolute;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px;
  white-space: nowrap;
  right:0;
}
