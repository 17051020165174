.card {
  text-align: center;
  position:relative;
  display:flex;
}

.map {
  filter: sepia(0.3);
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 287px;
    border-radius: 0;
  }
}
.controlsBox {
  position:absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background-color: rgb(255 255 255 / 0.5);
  border-radius: 15px;
}
.link, .link:hover {
  flex-grow:0;
  flex-basis: 430px;
  min-width: 280px;
  text-decoration: none;
  color: black;
}
