.list{
  margin-left: -10px;
  [data-theme=light] {
    background-color: transparent;
    :first-child:before {
      color: inherit !important;
      opacity: 50%;
    }
  }
  [data-theme="light"] [data-node-type="blockGroup"] [data-node-type="blockGroup"]
    > [data-node-type="block-outer"]::before {
    border-left: 1px solid rgba(33, 37, 41, 0.5) !important;
  }
  [data-node-type="block-outer"]:not([data-background-color="*"]) {
    background: repeating-linear-gradient(
      135deg,
      rgba(128, 64, 64, 0.1),
      rgba(128, 64, 64, 0.1) 2px,
      transparent 2px,
      transparent 13px
    );
    background-attachment:fixed;
  }
  [data-node-type="block-outer"]:not([data-background-color="*"]) [data-node-type="block-outer"]:not([data-background-color="*"]) {
    border:none;
    background:none;
  }
}
