$primary: #004fa1;
@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Pirata+One|Bilbo+Swash+Caps&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lighten(#8f5922, 80%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction:column;
}

.btn svg {
  margin-top:-6px;
  width: 20px;
  height:20px;
}

.tippy-box .mantine-ActionIcon-root svg:not(.mantine-Toolbar-root svg) {
  color: #212529 !important;
  opacity:50%;
}

.prosemirror-dropcursor-block {
  background-color: rgba(128, 62, 62, 0.3) !important;
}

h1, h2 {
  font-family: Pirata One, sans-serif;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.hidden {
  display:none !important;
}
