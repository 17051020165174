$size: 30px;
.mapPin {
  display:block;
  height:$size;
  width:$size;
  margin-left:calc(($size * -1) / 2);
  margin-top:-$size;
  color:white;
  filter: drop-shadow(5px 5px 2px rgb(0 0 0 / 0.5));
  text-decoration: none;
  position:relative;
  z-index: 1;
  div {
    position:absolute;
    bottom: $size;
    left: calc(((150px - $size) * -1) / 2);
    right: calc(((150px - $size) * -1) / 2);
    display:none;
    text-align: center;
    font-weight:bold;
    span {
      background-color: rgb(0 0 0 / 0.5);
      display:inline-block;
      max-width:150px;
      padding:0 5px;
    }
  }
  &:hover {
    color:white;
    div {
      display:block;
    }
    z-index: 2;
  }
}
