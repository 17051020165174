.divider {
  opacity: 0;
  cursor: pointer;
  color: #004fa1;
  display: flex;
  align-items: center;
  height:10px;
  svg {
    margin-top:-4px;
    width:20px;
    height:20px;
  }
}
.divider:hover {
  opacity: 0.5;
}

.divider:before,
.divider:after {
  content: '';
  flex: 0 1 100%;
  border-bottom: 5px dotted #004fa1;
  margin: 0;
}

.divider:before {
  margin-left: 0;
}

.divider:after {
  margin-right: 0;
}

.childrenContainer {
  position:relative;
}

.divider[text-position="right"]:after,
.divider[text-position="left"]:before {
  content: none;
}
