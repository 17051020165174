.parchmentContainer {
  position:relative;
  margin-right: 10px;
  margin-bottom:10px;
  display:flex;
}
.parchmentContent {
  flex: 1 1 auto;
  display:flex;
  flex-direction:column;
  padding:20px;
  filter: sepia(0.3);
  width: 100%;
}
.parchment {
  position:absolute;
  top:0;
  right:10px;
  left:0;
  bottom:10px;
  box-shadow: 2px 3px 5px darken(#8f5922, 10%), 0 0 125px #8f5922 inset;
  z-index: -1;
  background-color: white;
}
@supports (-webkit-touch-callout: none) {
  .parchment {
    filter:none !important;
  }
}

// .parchment {
//   position:absolute;
//   top:0;
//   right:10px;
//   left:0;
//   bottom:0;
//   box-shadow: 2px 3px 20px black, 0 0 125px #8f5922 inset;
//   z-index: -1;
// }
