.container {
  h1 {
    font-size:24px;
    margin-left: 20px;
  }
  padding: 10px 75px 0px 25px;
  // border-bottom: 3px solid #8f5922;
  // background-color: #000000;
  background-image: linear-gradient(100deg, darken(#8f5922, 30%) 0%, darken(#8f5922, 15%) 50%, darken(#8f5922, 30%) 100%);
  // background-image: linear-gradient(black, lighten(black, 5%));
  color:white;
  button {
    color:white;
  }
}
