.dropReceiver {
  position:relative;
  text-align: center;
  img {
    border-radius: 15px;
    max-height: 99%;
    max-width: 99%;
  }
}
.withIndicator {
  min-height: 150px;
  min-width: 150px;
}
.dropReceiverIndicator {
  font-weight: bold;
  border-radius: 15px;
  position:absolute;
  background-color: white;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 3px dashed var(--bs-link-color);
  color: var(--bs-link-color);
  svg {
    height: 40px;
  }
}
